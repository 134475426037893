import LanguageIcon from '../icons/LanguageIcon';
import { TSwitchLanguageProps } from '../../types/app';

const SwitchLanguage = (props: TSwitchLanguageProps) => {
  const CurrentLang = () => {
    if (props.local === 'en') return <span className="font-ar pb-1">عربي</span>;
    return <span className="font-en">Eng</span>;
  };

  const onClick = () => {
    props.onClick(props.local);
  };

  return (
    <button
      onClick={onClick}
      className="rtl:mr-[26px] ltr:ml-[26px] text-[12px] text-[#A1A1A1] flex items-center align-middle justify-between space-x-2 rtl:space-x-reverse"
    >
      <LanguageIcon />
      <CurrentLang />
    </button>
  );
};

export default SwitchLanguage;
