import Image from 'next/image';
import React from 'react';
import classNames from 'classnames';
import AccordionArrowIcon from 'components/icons/AccordionArrowIcon';
import Dropdown from 'components/elements/Dropdown';

import { TFaqItem } from 'types/app';
import { useTranslation } from 'next-i18next';

const ArrowIcon = ({ extraClassNames }: { extraClassNames?: string }) => (
  <AccordionArrowIcon
    className={classNames(
      'w-[18px] md:w-[23px] flex-shrink-0',
      'ltr:ml-[20px] rtl:mr-[20px] md:ltr:ml-[70px] md:rtl:mr-[70px] 2xl:ltr:ml-[120px] 2xl:rtl:mr-[120px]',
      extraClassNames
    )}
  />
);

type TProps = {
  faqItems: TFaqItem[];
  imageUrl: string;
};

export const FAQSection = (props: TProps) => {
  const { t: tFAQ } = useTranslation('faq');
  return (
    <div className="container flex flex-col flex-center md:items-start md:ltr:flex-row md:rtl:flex-row-reverse min-h-[520px]">
      <div className="min-w-[640px] mt-[-40px] md:mt-0 ">
        <Image
          src={props.imageUrl}
          alt="Illustration"
          layout="responsive"
          width={640}
          height={520}
        />
      </div>
      <div className="mx-auto mt-[-510px] md:mt-0 md:ml-[-560px] 2xl:ml-[-340px]">
        {props.faqItems.map((item, index) => (
          <div
            className="z-10 w-[355px] md:w-[640px] lg:w-[1000px]"
            key={index}
          >
            <Dropdown
              buttonClassName={classNames(
                'home-card-shadow-rounded flex justify-between items-center cursor-pointer w-full',
                'min-h-[80px] py-[16px] md:py-[11px] 2xl:py-[15px] px-[15px] md:px-[20px] 2xl:ltr:pl-[40px] 2xl:ltr:pr-[35px] 2xl:rtl:px-[40px]',
                'mt-[30px] md:mt-[40px]'
              )}
              buttonContent={
                <p
                  className={classNames(
                    'text-left text-[18px] ltr:text-left rtl:text-right leading-[22px] md:text-[22px] md:leading-[26px] 2xl:leading-[24px] ltr:font-500 rtl:font-400',
                    'line-clamp-2'
                  )}
                >
                  {tFAQ(item.title)}
                </p>
              }
              buttonIcon={<ArrowIcon />}
              buttonIconOpenedMenu={
                <ArrowIcon extraClassNames="transform rotate-180" />
              }
              itemClassName={classNames(
                'w-full min-h-[110px] flex items-center z-10 mt-2',
                'home-card-shadow-rounded focus:outline-none',
                'py-[16px] md:py-[11px] 2xl:py-[15px] px-[15px] md:px-[20px] 2xl:ltr:pl-[40px] 2xl:ltr:pr-[35px] 2xl:rtl:px-[40px]'
              )}
              itemContent={
                <p className="text-body-1  ltr:text-left rtl:text-right">
                  {tFAQ(item.text)}
                </p>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
