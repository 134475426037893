import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TContactForm } from 'types/app';
import Form from 'components/elements/Form';
import Input from 'components/elements/Input';
import Textarea from 'components/elements/Textarea';
import Button from 'components/elements/Button';
import { createSupport } from 'lib/api-calls/support';
import useToast from 'hooks/useToast';
import useAppDispatch from 'hooks/useAppDispatch';
import { alertModalOpened } from 'store/modals';
import { contactSchema } from 'lib/yup';
import AlertModal from 'components/modals/AlertModal';

export const ContactForm = () => {
  const { t } = useTranslation('home-page');
  const { t: common } = useTranslation('common');
  const { t: validation } = useTranslation('validation');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useToast();

  const onSubmit = (
    data: TContactForm,
    { setError, setFocus, reset }: UseFormReturn<TContactForm>
  ) => {
    setLoading(true);
    createSupport({
      firstName: data.name,
      lastName: '',
      email: data.email,
      answerOn: 'email',
      question: data.message,
    })
      .then(() => {
        dispatch(
          alertModalOpened({
            message: common(
              'Request has been successfully sent to the portal administration'
            ),
            label: common('Ok'),
          })
        );
        setTimeout(
          () =>
            reset({
              name: '',
              email: '',
              message: '',
            }),
          300
        );
      })
      .catch((error) => {
        notifyError(validation('Something went wrong'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Form<TContactForm>
        schema={contactSchema(validation)}
        onSubmit={onSubmit}
      >
        {([
          {
            register,
            control,
            formState: { errors },
          },
        ]) => (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] mb-[20px]">
              <Input
                label={common('Name')}
                placeholder={common('Enter your name')}
                error={errors.name?.message}
                register={register('name')}
                className="w-[280px] md:w-[260px] mx-auto"
              />

              <Input
                label={common('Email')}
                placeholder={common('Enter your email address')}
                error={errors.email?.message}
                register={register('email')}
                className="w-[280px] md:w-[260px] mx-auto"
              />
            </div>

            <Textarea
              label={common('Your message')}
              placeholder={common('Enter text of your message')}
              error={errors.message?.message}
              register={register('message')}
              textareaClassName="h-[80px]"
              className="w-[280px] md:w-[540px] mx-auto"
            />

            <div className="flex-start pt-[40px]">
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                label={common('Send')}
                className="w-[134px]"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
