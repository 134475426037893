import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

type Props = {
    buttonClassName: string,
    buttonContent: JSX.Element,
    buttonIcon?: JSX.Element,
    buttonIconOpenedMenu?: JSX.Element,
    itemClassName: string,
    itemContent: JSX.Element,
}

const Dropdown = ({ buttonClassName, buttonContent, buttonIcon, buttonIconOpenedMenu, itemClassName, itemContent }: Props) => {

    return <Menu as="div" className="relative">
        {({ open }) => (<>
            <Menu.Button className={buttonClassName}>
                {buttonContent}
                {!buttonIconOpenedMenu ? buttonIcon : (open ? buttonIconOpenedMenu : buttonIcon)}
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={
                        classNames("absolute right-0 origin-top-right", itemClassName)}
                >
                    {itemContent}
                </Menu.Items>
            </Transition>
        </>)}

    </Menu>
}

export default Dropdown