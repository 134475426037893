import { useState } from 'react';
import { TChildren } from 'types/app';
import DashboardFooter from 'components/dashboard-layout/DashboardFooter';
import HomeHeader from 'components/pages-content/home/HomeHeader';
import { languageHooks } from 'hooks/useSwitchLang';
import { useHomeNavigation } from 'components/pages-content/home/homeNavigation.hook';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const SidebarMobile = dynamic(
  () => import('components/dashboard-layout/SidebarMobile'),
  { ssr: false }
);

const HomeLayout = ({ children }: TChildren) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const switchLang = languageHooks.useSwitchLang();
  const { homeNavigationList } = useHomeNavigation();

  return (
    <>
      <div className="h-screen relative pt-[10px]">
        <SidebarMobile
          show={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          onSwitchLang={switchLang}
          noSupport
          items={homeNavigationList.map((item) =>
            item.newTab ? (
              <a
                key={item.label}
                target="_blank"
                rel="noopener noreferrer"
                href={item.href}
                onClick={() => setSidebarOpen(false)}
                className="baseStyle mx-[20px]"
              >
                <span className="w-full ltr:text-left rtl:text-right ltr:pl-6 rtl:pr-4 uppercase text-secondary text-subtitle-1 rtl:leading-[30px] ltr:font-500 rtl:font-normal line-clamp-1 overflow-hidden">
                  {item.label}
                </span>
              </a>
            ) : (
              <Link href={item.href} key={item.label}>
                <a
                  onClick={() => setSidebarOpen(false)}
                  className="baseStyle mx-[20px]"
                >
                  <span className="w-full ltr:text-left rtl:text-right ltr:pl-6 rtl:pr-4 uppercase text-secondary text-subtitle-1 rtl:leading-[30px] ltr:font-500 rtl:font-normal line-clamp-1 overflow-hidden">
                    {item.label}
                  </span>
                </a>
              </Link>
            )
          )}
        />
        <HomeHeader
          setSidebarOpen={(status) => setSidebarOpen(status)}
          onSwitchLang={switchLang}
          navMenu={homeNavigationList}
        />
        <div className="h-full w-full flex flex-col justify-between pt-[68px] md:pt-[100px]">
          <div>{children}</div>
          <div className="w-full z-30">
            <DashboardFooter extraWrapClassName="container" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLayout;
