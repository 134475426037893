import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

type TToastPromise = {
  pending: string;
  success: string;
  error: string;
};
const useToast = () => {
  return {
    notifyError: (message: string) => toast.error(message),
    notifySuccess: (message: string) => toast.success(message),
    notifyInfo: (message: string) => toast.info(message),
    notifyWarning: (message: string) => toast.warning(message),
    notifyLoading: <T extends {} = AxiosResponse>(
      promise: Promise<T>,
      messages: TToastPromise
    ) => {
      return toast.promise(promise, { ...messages });
    },
  };
};

export default useToast;
