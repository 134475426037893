import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import { AppLogoArabic, AppLogoEnglish } from '../../icons/AppLogo';
import Button from 'components/elements/Button';
import { DEFAULT_LANGUAGE } from 'lib/constants';
import SwitchLanguage from 'components/elements/SwitchLanguage';
import OpenSidebarIcon from 'components/icons/OpenSidebarIcon';
import { HeaderUserDropdownType } from 'types/app';

type Props = {
  setSidebarOpen: (open: boolean) => void;
  onSwitchLang: (local: string) => void;
  navMenu: HeaderUserDropdownType[];
};

const HomeHeader = ({ setSidebarOpen, onSwitchLang, navMenu }: Props) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const AppLogo = router.locale === 'ar' ? AppLogoArabic : AppLogoEnglish;

  const goToRegister = () => {
    if (router.pathname === '/register') return;
    router.replace('/register');
  };

  const goToLogin = () => {
    if (router.pathname === '/login') return;
    router.replace('/login');
  };

  return (
    <div
      className={classNames(
        'fixed min-h-[80px] md:h-[100px] lg:h-[110px] overflow-hidden bg-white top-0 z-30 flex-shrink-0 flex shadow-md',
        'home-wrap'
      )}
    >
      <div className="flex-1 flex justify-between items-center flex-wrap relative container">
        <div className="flex items-center xl:hidden">
          <button
            type="button"
            className="focus:outline-none focus:ring-0 focus:ring-inset focus:ring-primary 2xl:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <OpenSidebarIcon aria-hidden="true" />
          </button>
          <span className="ltr:ml-6 rtl:mr-4 hidden md:flex">
            <Link href="/">
              <a>
                <AppLogo />
              </a>
            </Link>
          </span>
        </div>
        <div className="flex-1 py-2 hidden xl:flex">
          <Link href="/">
            <a>
              <AppLogo />
            </a>
          </Link>
          <div className="flex items-center">
            {navMenu.map((item) =>
              item.newTab ? (
                <a
                  key={item.label}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className={classNames(
                    'ltr:pl-10 rtl:pr-10 uppercase text-secondary line-clamp-1 ltr:font-500 rtl:font-normal',
                    'text-subtitle-1 rtl:text-headline-2 rtl:leading-[60px]'
                  )}
                >
                  {item.label}
                </a>
              ) : (
                <Link key={item.label} href={item.href}>
                  <a
                    className={classNames(
                      'ltr:pl-10 rtl:pr-10 uppercase text-secondary line-clamp-1 ltr:font-500 rtl:font-normal',
                      'text-subtitle-1 rtl:text-headline-2 rtl:leading-[60px]'
                    )}
                  >
                    {item.label}
                  </a>
                </Link>
              )
            )}
          </div>
        </div>

        <div
          className={classNames(
            'flex items-center flex-wrap justify-between py-4 rtl:space-x-reverse',
            'ltr:space-x-[20px] rtl:space-x-[10px]',
            'md:ltr:space-x-[40px] md:rtl:ml-[-24px] md:rtl:space-x-[24px]',
            'lg:rtl:space-x-[40px] lg:rtl:ml-[-40px] '
          )}
        >
          <div className="md:m-auto rtl:md:ml-[40px] rtl:ml-[10px] rtl:mr-[-26px] ltr:ml-[-26px]">
            <SwitchLanguage
              local={router.locale || DEFAULT_LANGUAGE}
              onClick={(local: string) => onSwitchLang(local)}
            />
          </div>

          <Button
            link
            label={t('login')}
            color="secondary"
            loading={false}
            onClick={goToLogin}
          />
          <Button label={t('register')} onClick={goToRegister} />
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
