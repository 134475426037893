import { useRouter } from 'next/router';
import { DEFAULT_LANGUAGE } from 'lib/constants';
import { useCallback } from 'react';

const useSwitchLang = (): ((local: string) => void) => {
  const router = useRouter();
  const { asPath } = router;
  const switchLang = useCallback(
    (nextLang: string) => {
      location.href =
        nextLang === DEFAULT_LANGUAGE
          ? router.asPath
          : `/${nextLang}${router.asPath}`;
      // TODO("Fix the issue of loading session always true when change language")
    },
    [asPath]
  );

  return (local) => switchLang(local === 'en' ? 'ar' : 'en');
};

export const languageHooks = {
  useSwitchLang,
};
