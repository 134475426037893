const LanguageIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8a7 7 0 1014 0A7 7 0 001 8z"
        stroke="#A2A2A2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7 1.035S10.8 3.8 10.8 8c0 4.2-2.1 6.965-2.1 6.965M7.3 14.965S5.2 12.2 5.2 8c0-4.2 2.1-6.965 2.1-6.965M1.441 10.45H14.56M1.441 5.55H14.56"
        stroke="#A2A2A2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LanguageIcon;
