const OpenSidebarIcon = () => {
  return (
    <svg
      className="sm:h-[31px] sm:w-[60px] h-[21px] w-[34px] p-0 m-0"
      viewBox="0 0 60 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={60} height={4} rx={1} fill="#05445E" />
      <rect y={9} width={60} height={4} rx={1} fill="#05445E" />
      <rect y={18} width={60} height={4} rx={1} fill="#05445E" />
      <rect y={27} width={60} height={4} rx={1} fill="#05445E" />
    </svg>
  );
};

export default OpenSidebarIcon;
