import React from 'react';
import { TInputProps } from '../../types/app';
import * as classNames from 'classnames';

const Textarea = ({
  labelHasStar = true,
  ...props
}: Omit<TInputProps, 'type'> & {
  rows?: number;
  textareaClassName?: string;
}) => {
  const isInvalid = props?.error && props?.error.length > 0;
  return (
    <div
      className={classNames(
        'w-full relative',
        {
          hidden: props.hidden,
        },
        props.className
      )}
    >
      <div className="flex items-center justify-between">
        <label
          htmlFor={props?.register?.name || props.name}
          className="input-label"
        >
          {props.label}
          {labelHasStar && (
            <span
              className={classNames(
                'text-action-blue inline-block ltr:ml-[5px] rtl:mr-[5px]',
                isInvalid ? 'text-danger' : 'text-action-blue'
              )}
            >
              *
            </span>
          )}
        </label>
        {isInvalid && <span className="label-invalid">{props.error}</span>}
      </div>
      <div className="">
        <textarea
          {...props.register}
          readOnly={props.hidden === true || props.readOnly}
          id={props?.register?.name || props.name}
          className={classNames(
            isInvalid ? 'input-invalid' : 'input-primary',
            {
              'input-disabled': props.readOnly,
            },
            props.textareaClassName
          )}
          placeholder={props?.placeholder || props.label}
          autoFocus={props.autoFocus}
          rows={props.rows || 3}
        />
      </div>
    </div>
  );
};

export default Textarea;
