import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import classNames from 'classnames';
import Illustration from '/public/images/home/illust5.png';
import IllustrationMob from '/public/images/home/illust5-mob.png';
import { ContactForm } from './ContactForm';

export const ContactFormSection = () => {
  const { t } = useTranslation('home-page');

  return (
    <div className="container mb-[80px] md:mb-[100px] 2xl:mb-[140px]">
      <div className="flex flex-col justify-between md:flex-row">
        <div>
          <h4
            className={classNames(
              'ltr:font-Harietta text-secondary font-700',
              'text-[22px] leading-[26px] mb-[30px] ltr:pl-[12px] ltr:pr-[30px] rtl:pl-[30px] rtl:pr-[12px]',
              'md:text-[34px] md:leading-[40px] md:mb-[40px] md:ltr:mr-[20px] md:rtl:ml-[20px] md:ltr:px-0 md:rtl:px-0',
              '2xl:mb-[50px] 2xl:max-w-[640px]'
            )}
          >
            {t('Finding the best tenants for your rental property')}
          </h4>
          <p
            className={classNames(
              'text-[18px] leading-[24px] ltr:font-500 rtl:font-400 max-w-[566px] px-[12px]',
              'md:text-[20px] md:leading-[28px] mr-0 md:ltr:mr-[48px] md:rtl:ml-[48px] md:px-0'
            )}
          >
            {t(
              'Your investments are our priority, contact us now to answer all your questions.'
            )}
          </p>
        </div>
        <div className="mr-[-10px] mt-[30px] md:mr-0 md:mt-[4px] self-end w-[280px] md:w-auto md:min-w-[400px]">
          <span className="hidden md:inline-block">
            <Image
              src={Illustration}
              alt="Illustration"
              width={550}
              height={520}
            />
          </span>
          <span className="inline-block md:hidden ">
            <Image
              src={IllustrationMob}
              alt="Illustration"
              width={280}
              height={240}
            />
          </span>
        </div>
      </div>
      <div
        className={classNames(
          'home-card-shadow-rounded z-10 max-w-max relative px-[18px] py-[60px] mt-[-220px] ltr:ml-[-10px] rtl:mr-[-10px] bg-white-opacity',
          'xxs:px-[38px]',
          'md:mt-[-120px] md:px-[60px] md:ltr:mx-auto md:rtl:mx-auto',
          'xl:px-[80px] xl:mt-[-130px] xl:m-auto',
          '2xl:mt-[-250px]'
        )}
      >
        <ContactForm />
      </div>
    </div>
  );
};
