import axios, { AxiosRequestConfig } from 'axios';

let token: string | undefined;

axios.interceptors.request.use(
  (requestConfig: AxiosRequestConfig) => {
    if (!token) return requestConfig;
    return {
      ...requestConfig,
      withCredentials: false,
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
      headers: {
        ...requestConfig.headers,
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      if (typeof document !== 'undefined') {
        document.dispatchEvent(new Event('onUnauthenticatedAxiosResponse'));
      }
    }
    return Promise.reject(error);
  }
);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export const fetcher = (url: string) => http.get(url);
export const swrFetcher = (url: string) =>
  http
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
export const setToken = (newToken?: string) => (token = newToken);
export default http;
