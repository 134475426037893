import React from 'react';
import { TInputProps } from '../../types/app';
import * as classNames from 'classnames';

const Input = ({ labelHasStar = true, ...props }: TInputProps) => {
  const isInvalid = props?.error && props?.error.length > 0;
  return (
    <div
      className={classNames(
        'w-full relative',
        {
          hidden: props.hidden,
        },
        props.className
      )}
    >
      <div className="flex items-center justify-between">
        {props?.label && (
          <label
            htmlFor={props?.register?.name || props.name}
            className="input-label"
          >
            <span>{props.label}</span>
            {labelHasStar && (
              <span
                className={classNames(
                  'text-action-blue inline-block ltr:ml-[5px] rtl:mr-[5px]',
                  isInvalid ? 'text-danger' : 'text-action-blue'
                )}
              >
                *
              </span>
            )}
          </label>
        )}
        {isInvalid && !props.noErrorText && (
          <span className="label-invalid">{props.error}</span>
        )}
      </div>

      <div className="">
        <input
          {...props.register}
          {...(props.step && { step: props.step })}
          readOnly={props.hidden === true || props.readOnly}
          disabled={props.hidden === true || props.readOnly}
          type={props?.type || 'text'}
          id={props?.register?.name || props.name}
          className={classNames(
            isInvalid ? 'input-invalid' : 'input-primary',
            {
              'input-disabled': props.readOnly,
            },
            props?.inputClassName
          )}
          placeholder={props?.placeholder || props.label}
          autoFocus={props.autoFocus}
          autoComplete={props.autocomplete}
          onFocus={props.onFocus}
        />
      </div>
    </div>
  );
};

export default Input;
