import { TIconProps } from 'types/app';

const AccordionArrowIcon = (props: TIconProps) => {
  return (
    <svg
      width={26}
      height={16}
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M24.5 1.5l-11.29 12L1.5 1.5" stroke="#189AB4" strokeWidth={3} />
    </svg>
  );
};

export default AccordionArrowIcon;
